<template>
  <section id="settings">
    <div class="col1">
      <h3>Admin</h3>
      <p>Perform admin functions</p>

      <transition name="fade">
        <p v-if="showSuccess" class="success">Pending listings refreshed!</p>
      </transition>

      <form @submit.prevent>
        <p>Manually convert expired listings to "Pending" status:</p>
        <button @click="refreshPendingListings()" class="button">Refresh</button>
      </form>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      // name: '',
      // title: '',
      showSuccess: false
    }
  },
  computed: {
    // ...mapState(['userProfile'])
  },
  methods: {
    refreshPendingListings() {
      this.$store.dispatch('refreshListingPendingStatus')

      this.showSuccess = true

      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    }
  }
}
</script>
